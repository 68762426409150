import React from 'react';
import { bffFetcher } from '@customer-web-app/domains/shared/api/services/fetchers-service';

interface NotifyErrorRequest {
  message: string;
}

function useErrorNotifier() {
  const [isNotifying, setIsNotifying] = React.useState(false);

  async function notifyError({ message }: NotifyErrorRequest) {
    setIsNotifying(true);

    try {
      await bffFetcher({
        method: 'POST',
        url: '/notify-error',
        data: { message },
      });
    } finally {
      setIsNotifying(false);
    }
  }

  return {
    notifyError,
    isNotifying,
  };
}

export default useErrorNotifier;
