import { Icon, TooltipStyles, ToastContext } from '@blastradius/ui';
import { Themes } from '@blastradius/ui/themes';
import classNames from 'classnames';
import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useTheme from '../../hooks/use-theme';

export type TextTypes = 'body' | 'heading' | 'blast' | 'label' | 'label-caps';

export type TextSizes =
  | 'x-small'
  | 'small'
  | 'regular'
  | 'large'
  | 'x-large'
  | 'xx-large';

// LetterSpacings =    0.5%  |    1%   |   10%
type LetterSpacings = 'wide' | 'wider' | 'widest';

// LineHeights =    1  | 12px |  14px | 16px|  18px | 20px| 22px  | 24px| 36px| 48px
type LineHeights =
  | '1'
  | '3'
  | '3.5'
  | '4'
  | '4.5'
  | '5'
  | '5.5'
  | '6'
  | '9'
  | '11';

// FontWeights =     300   |   400    |   500    |  700   |    800
type FontWeights = 'light' | 'normal' | 'medium' | 'bold' | 'extrabold';

type FontSizes =
  | '10px'
  | '11px'
  | '12px'
  | '13px'
  | '14px'
  | '15px'
  | '17px'
  | '18px'
  | '19px'
  | '24px'
  | '26px'
  | '28px'
  | '32px'
  | '40px';

type FontFamilies = 'sans' | 'blast';

type TextTransforms = 'uppercase' | 'lowercase';

/**
 {
   [type]: {size, lineHeight, letterSpacing?, weight}
 }
 */

type TextMapper = {
  [key in TextTypes]: {
    [key in TextSizes]?: {
      fontFamily: FontFamilies;
      fontSize: FontSizes | string;
      letterSpacing?: LetterSpacings;
      lineHeight: LineHeights;
      fontWeight: FontWeights;
      textTransform?: TextTransforms;
    };
  };
};

const textMapper: TextMapper = {
  heading: {
    'x-small': {
      fontFamily: 'sans',
      fontSize: '14px',
      lineHeight: '5',
      fontWeight: 'normal',
    },
    small: {
      fontFamily: 'sans',
      fontSize: '17px',
      lineHeight: '5',
      fontWeight: 'normal',
    },
    regular: {
      fontFamily: 'sans',
      fontSize: '19px',
      lineHeight: '5.5',
      fontWeight: 'normal',
    },
    large: {
      fontFamily: 'sans',
      fontSize: '24px',
      lineHeight: '1',
      fontWeight: 'normal',
    },
    'x-large': {
      fontFamily: 'sans',
      fontSize: '32px',
      lineHeight: '1',
      fontWeight: 'normal',
    },
    'xx-large': {
      fontFamily: 'sans',
      fontSize: '40px',
      lineHeight: '1',
      fontWeight: 'normal',
    },
  },
  blast: {
    'x-small': {
      fontFamily: 'blast',
      fontSize: '16px',
      lineHeight: '6',
      fontWeight: 'normal',
    },
    small: {
      fontFamily: 'blast',
      fontSize: '18px',
      lineHeight: '6',
      fontWeight: 'normal',
    },
    regular: {
      fontFamily: 'blast',
      fontSize: '28px',
      lineHeight: '1',
      fontWeight: 'normal',
    },
    large: {
      fontFamily: 'blast',
      fontSize: '40px',
      lineHeight: '11',
      fontWeight: 'light',
    },
  },
  body: {
    'x-small': {
      fontFamily: 'sans',
      fontSize: '11px',
      letterSpacing: 'wide',
      lineHeight: '3',
      fontWeight: 'medium',
    },
    small: {
      fontFamily: 'sans',
      fontSize: '12px',
      letterSpacing: 'wide',
      lineHeight: '3.5',
      fontWeight: 'medium',
    },
    regular: {
      fontFamily: 'sans',
      fontSize: '13px',
      letterSpacing: 'wide',
      lineHeight: '4.5',
      fontWeight: 'normal',
    },
    large: {
      fontFamily: 'sans',
      fontSize: '14px 2.5xl:text-15px',
      letterSpacing: 'wide',
      lineHeight: '5.5',
      fontWeight: 'medium',
    },
    'x-large': {
      fontFamily: 'sans',
      fontSize: '15px',
      lineHeight: '5',
      fontWeight: 'normal',
    },
    'xx-large': {
      fontFamily: 'sans',
      fontSize: '16px',
      lineHeight: '6',
      fontWeight: 'normal',
    },
  },
  label: {
    regular: {
      fontFamily: 'sans',
      fontSize: '12px',
      letterSpacing: 'wider',
      lineHeight: '3.5',
      fontWeight: 'bold',
    },
    large: {
      fontFamily: 'sans',
      fontSize: '13px',
      letterSpacing: 'wider',
      lineHeight: '3.5',
      fontWeight: 'bold',
    },
  },
  'label-caps': {
    small: {
      fontFamily: 'sans',
      fontSize: '9px',
      letterSpacing: 'widest',
      lineHeight: '3',
      fontWeight: 'extrabold',
      textTransform: 'uppercase',
    },
    regular: {
      fontFamily: 'sans',
      fontSize: '10px',
      letterSpacing: 'widest',
      lineHeight: '3',
      fontWeight: 'extrabold',
      textTransform: 'uppercase',
    },
  },
};

const tailwindClassesMapper = {
  letterSpacing: 'tracking-',
  lineHeight: 'leading-',
  fontWeight: 'font-',
  fontSize: 'text-',
  fontFamily: 'font-',
  textTransform: '',
};

const copyIconDefaultProps = {
  className: 'basis-4',
  size: 16,
  showOnHover: false,
};

const tooltipThemeMapper: {
  [key in Themes]: React.ComponentProps<typeof ReactTooltip>['variant'];
} = {
  light: 'dark',
  dark: 'light',
};

type Props = {
  type: TextTypes;
  size?: TextSizes;
  as?: React.ElementType;
  color?: string;
  gradient?: boolean;
  fallback?: boolean | string;
  copyable?: boolean;
  copyableValue?: string;
  copyIconProps?: {
    className?: string;
    size?: React.ComponentProps<typeof Icon>['size'];
  };
  copyToastProps?: { background?: string };
  tooltip?: React.ComponentProps<typeof ReactTooltip>;
  htmlFor?: string;
  render?: ({
    tooltipId,
    tooltipStyle,
    ref,
  }: {
    tooltipId: string;
    tooltipStyle: ReturnType<typeof TooltipStyles>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: (node: any) => void;
  }) => React.ReactNode;
  tooltipClassName?: string;
};

const Text: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  type,
  size = 'regular',
  as = 'span',
  color = 'text-gray-950 dark:text-gray-50',
  gradient,
  fallback = '-',
  copyable = false,
  copyableValue = null,
  copyToastProps = undefined,
  tooltip = undefined,
  copyIconProps,
  children,
  onClick,
  render,
  tooltipClassName = '',
  ...props
}) => {
  const { notification } = React.useContext(ToastContext);
  const textMap = textMapper[type][size];

  if (!textMap) {
    throw new Error(
      `Component Combination Error: There is no configuration for ${type} and ${size}. More details: https://www.figma.com/file/1EE68eKmVMdmj2AdSvxUVV/Blast-Radius-Design-System`,
    );
  }
  const textClasses = Object.keys(textMap)
    .map((key) =>
      [
        tailwindClassesMapper[key as keyof typeof tailwindClassesMapper],
        textMap[key as keyof typeof textMap],
      ].join(''),
    )
    .join(' ');

  const className = classNames(
    textClasses,
    gradient
      ? `bg-clip-text text-transparent bg-gradient-to-br ${color}`
      : color,
    {
      'flex items-center gap-1': copyable,
      group: copyable,
    },
    props.className,
  );

  // Copy behavior
  const { className: copyIconClassName, size: copyIconSize } = {
    ...copyIconDefaultProps,
    ...copyIconProps,
  };
  const [isContentCopied, setIsContentCopied] = React.useState(false);

  React.useEffect(() => {
    if (isContentCopied) {
      setTimeout(() => {
        setIsContentCopied(false);
      }, 2000);
    }
  }, [isContentCopied]);

  function handleCopyContent() {
    if (!isContentCopied) {
      const valueToCopy = copyableValue || (children?.toString() as string);
      navigator.clipboard.writeText(valueToCopy);
      setIsContentCopied(true);
      notification({
        message: 'Text has been copied to clipboard',
        background: copyToastProps?.background,
      });
    }
  }

  // Tooltip behavior
  const tooltipId = Math.random().toString(36).slice(2);
  const { theme } = useTheme();
  const tooltipStyle = TooltipStyles(theme);
  const [hasTooltip, setHasTooltip] = React.useState(false);
  const textRef = React.useCallback((node: HTMLElement) => {
    if (node !== null) {
      setHasTooltip(
        node.offsetWidth < node.scrollWidth ||
          node.offsetHeight < node.scrollHeight,
      );
    }
  }, []);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    // Disable the click on icons and call the main event
    if (['svg', 'path'].includes((e.target as HTMLElement).tagName)) {
      e.preventDefault();
    } else {
      onClick?.(e);
    }
  }

  function renderChildren() {
    return (
      <>
        {render ? render({ tooltipId, tooltipStyle, ref: textRef }) : children}
        {copyable && (
          <span
            role="button"
            aria-label="Copy content"
            onClick={handleCopyContent}
            className={classNames(
              copyIconClassName,
              'cursor-pointer invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-[50ms] ease-in-out',
            )}
          >
            <Icon
              name={isContentCopied ? 'check' : 'copy'}
              size={copyIconSize as React.ComponentProps<typeof Icon>['size']}
            />
          </span>
        )}
        {hasTooltip && (
          <ReactTooltip
            {...tooltip}
            id={tooltipId}
            place={tooltip?.place || 'bottom'}
            variant={tooltipThemeMapper[theme]}
            style={{ backgroundColor: tooltipStyle['data-background-color'] }}
            arrowColor={tooltipStyle['data-background-color']}
            className={classNames(
              '!text-gray-50 dark:!text-gray-950',
              tooltipClassName,
            )}
          />
        )}
      </>
    );
  }

  return React.createElement(
    as,
    { ...props, onClick: handleClick, className },
    fallback
      ? children != null || render != null
        ? renderChildren()
        : fallback
      : renderChildren(),
  );
};

export default Text;
