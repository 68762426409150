import { FeatureFlagsStructure } from '@customer-web-app/domains/shared/providers/feature-flags-provider';
import { isValid as isValidDate, format, sub } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';

export type IncidentFilterParams = {
  globalFilter?: {
    keyword?: string;
    entities?: 'Incident'[];
    dateRange?: {
      from?: Date | string;
      to?: Date | string;
    };
  };
  incidentFilter?: {
    incidentStatus?: string[];
    incidentPhase?: string[];
    incidentType?: string[];
    assignedTo?: string[];
    excludeIncidentType?: string[];
  };
};

function getDefaultIncidentFilter({
  alertsEnableNetworkAlerts,
  alertsEnableTriageRequiredAlertStatus,
}): IncidentFilterParams {
  const currentDate = new Date();

  return {
    globalFilter: {
      keyword: '',
      entities: ['Incident'],
      dateRange: {
        from: sub(currentDate, {
          days: 30,
        }),
        to: currentDate,
      },
    },
    incidentFilter: {
      incidentStatus: [],
      incidentPhase: ['live', 'contained', 'remediated'],
      incidentType: [
        'endpoint',
        'phishing',
        'bec',
        'identity',
        ...(alertsEnableNetworkAlerts ? ['network'] : []),
        ...(alertsEnableTriageRequiredAlertStatus ? ['generic'] : []),
      ],
      assignedTo: [],
      excludeIncidentType: [],
    },
  };
}

function buildIncidentFilter(
  filters: IncidentFilterParams,
  featureFlags?: FeatureFlagsStructure,
  isSuperUser?: boolean,
) {
  filters = cloneDeep(filters);

  const excludedIncidentTypes = [];

  if (featureFlags && !isSuperUser) {
    if (!featureFlags.alertsEnableEndpointAlerts) {
      excludedIncidentTypes.push('endpoint');
    }
    if (!featureFlags.alertsEnableIdentityAlerts) {
      excludedIncidentTypes.push('identity');
    }
    if (!featureFlags.alertsEnableNetworkAlerts) {
      excludedIncidentTypes.push('network');
    }

    if (filters.incidentFilter) {
      filters.incidentFilter.excludeIncidentType = excludedIncidentTypes;
    }
  }

  if (isValidDate(filters?.globalFilter?.dateRange?.from)) {
    filters.globalFilter.dateRange.from = format(
      new Date(filters?.globalFilter?.dateRange?.from),
      'yyyy-MM-dd',
    );
  }
  if (isValidDate(filters?.globalFilter?.dateRange?.to)) {
    filters.globalFilter.dateRange.to = new Date(
      filters?.globalFilter?.dateRange?.to,
    )
      .toISOString()
      .split('T')[0];
  }

  return filters;
}

const IncidentFilterService = {
  getDefaultIncidentFilter,
  buildIncidentFilter,
};

export default IncidentFilterService;
