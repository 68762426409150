import { Icons } from '@blastradius/ui/icons';
import { ConnectorVendorCodes } from '@customer-web-app/domains/shared/enums/connector-vendor-codes';

export const connectorVendorIcons: { [key in ConnectorVendorCodes]: Icons } = {
  [ConnectorVendorCodes.AWS]: 'aws',
  [ConnectorVendorCodes.BlastRadius]: 'blastradius',
  [ConnectorVendorCodes.Crowdstrike]: 'crowdstrike',
  [ConnectorVendorCodes.Cisco]: 'cisco',
  [ConnectorVendorCodes.MicrosoftDefender]: 'microsoft-defender',
  [ConnectorVendorCodes.MicrosoftOffice]: 'microsoft-office',
  [ConnectorVendorCodes.Okta]: 'okta',
  [ConnectorVendorCodes.Slack]: 'slack',
  [ConnectorVendorCodes.PaloAltoNetworks]: 'palo-alto-networks',
  [ConnectorVendorCodes.SentinelOne]: 'sentinel-one',
  [ConnectorVendorCodes.ZScaler]: 'z-scaler',
  [ConnectorVendorCodes.MicrosoftAzureAD]: 'microsoft-office',
  [ConnectorVendorCodes.GoogleWorkspace]: 'google-workspace',
  [ConnectorVendorCodes.KnowBe4]: 'knowbe4',
  [ConnectorVendorCodes.Mimecast]: 'mimecast',
  [ConnectorVendorCodes.FireEyeHX]: 'fireeyehx',
  [ConnectorVendorCodes.Cribl]: 'cribl',
  [ConnectorVendorCodes.MicrosoftTeams]: 'microsoft-teams',
  [ConnectorVendorCodes.PulseSecure]: 'pulsesecure',
  [ConnectorVendorCodes.Proofpoint]: 'proofpoint',
  [ConnectorVendorCodes.CriblWebHook]: 'cribl',
  [ConnectorVendorCodes.Fleet]: 'fleet',
  [ConnectorVendorCodes.SonicWall]: 'sonic-wall',
  [ConnectorVendorCodes.Fortinet]: 'fortinet',
  [ConnectorVendorCodes.Rapid7]: 'rapid7',
  [ConnectorVendorCodes.Barracuda]: 'barracuda',
  [ConnectorVendorCodes.Atlassian]: 'atlassian',
  [ConnectorVendorCodes.Netskope]: 'netskope',
  [ConnectorVendorCodes.TrendMicro]: 'trendmicro',
  [ConnectorVendorCodes.Forcepoint]: 'forcepoint',
  [ConnectorVendorCodes.Avanan]: 'avanan',
  [ConnectorVendorCodes.Splunk]: 'splunk',
  [ConnectorVendorCodes.Aruba]: 'aruba',
  [ConnectorVendorCodes.ManageEngine]: 'manage_engine',
  [ConnectorVendorCodes.DataDog]: 'datadog',
  [ConnectorVendorCodes.Dragos]: 'dragos',
  [ConnectorVendorCodes.OnePassword]: 'onepassword',
  [ConnectorVendorCodes.Darktrace]: 'darktrace',
  [ConnectorVendorCodes.AdaptiveShield]: 'adaptive_shield',
  [ConnectorVendorCodes.CheckPoint]: 'checkpoint',
  [ConnectorVendorCodes.Elastic]: 'elastic',
  [ConnectorVendorCodes.Vectra]: 'vectra',
};
