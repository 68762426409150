import React from 'react';

type UseLocalStorageOptions = {
  updateInitialValueOnLoad?: boolean;
};

const defaultOptions = {
  updateInitialValueOnLoad: false,
};

function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options: UseLocalStorageOptions = {},
): [T, (value: T) => void] {
  const { updateInitialValueOnLoad } = { ...defaultOptions, ...options };

  const [storedValue, setStoredValue] = React.useState<T>(() => {
    if (global.window) {
      const item = window.localStorage.getItem(key);

      return item && item !== 'undefined' && !updateInitialValueOnLoad
        ? JSON.parse(item)
        : initialValue;
    }
  });

  function setValue(value: T) {
    setStoredValue(value);
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  return [storedValue, setValue];
}

export default useLocalStorage;
