import React from 'react';
import classNames from 'classnames';
import Icon from '../../base-components/icon/index';
import { DrawerContext, DrawerNavigateToParams } from './drawer-provider';
import { DialogComponent, DialogContext } from '../dialog/dialog-provider';
import DrawerCloseConfirmDialog from './drawer-close-confirm-dialog';

export type CloseConfirmButtonType = {
  label: string;
  onClick: () => void;
};

export type CloseConfirmType = {
  dialogTitle: string;
  dialogDescription: string;
  options: CloseConfirmButtonType[];
  enabled?: boolean;
};

type DrawerProps = {
  key?: string;
  width?: string;
  height?: string;
  defaultTop?: number;
  isLastDrawer?: boolean;
  'data-testid'?: string;
  withShadow?: boolean;
};

type DrawerActivateProps = {
  disabled?: boolean;
  children: React.ReactElement;
} & DrawerNavigateToParams;

type DrawerNavigateToProps = {
  children: React.ReactElement;
} & DrawerNavigateToParams;

type DrawerNavigateBackProps = {
  children?: React.ReactElement;
  onNavigateBackConfirm?: CloseConfirmType;
} & DrawerNavigateToParams;

type DrawerDeactivateProps = {
  onCloseConfirm?: CloseConfirmType;
  onClose?: () => void;
};

type DrawerDeactivateSubLayerProps = {
  children?: React.ReactElement;
};

type DrawerType = React.FC<DrawerProps & React.HTMLAttributes<HTMLElement>>;

type DrawerActivateType = React.FC<
  DrawerActivateProps & React.HTMLAttributes<HTMLElement>
>;

type DrawerDeactivateType = React.FC<
  DrawerDeactivateProps & React.HTMLAttributes<HTMLElement>
>;

type DrawerNavigateToType = React.FC<
  DrawerNavigateToProps & React.HTMLAttributes<HTMLElement>
>;

type DrawerNavigateBackType = React.FC<
  DrawerNavigateBackProps & React.HTMLAttributes<HTMLElement>
>;

type DrawerSubLayerType = React.FC<
  {
    width?: string;
    height?: string;
    right?: string;
    open?: boolean;
    forceShadow?: boolean;
  } & React.HTMLAttributes<HTMLElement>
>;

type DrawerDeactivateSubLayerType = React.FC<
  DrawerDeactivateSubLayerProps & React.HTMLAttributes<HTMLElement>
>;

type SubComponents = {
  Activate: DrawerActivateType;
  Deactivate: DrawerDeactivateType;
  NavigateTo: DrawerNavigateToType;
  NavigateBack: DrawerNavigateBackType;
  SubLayer: DrawerSubLayerType;
  DeactivateSubLayer: DrawerDeactivateSubLayerType;
};

const Drawer: DrawerType & SubComponents = ({
  width = 'w-[30rem] 2xl:w-[33.5rem] 4xl:w-[44.125rem]',
  height = 'h-screen',
  children,
  isLastDrawer,
  withShadow = true,
  ...props
}) => {
  const { opened } = React.useContext(DrawerContext);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const main = document.getElementById('main')!;
  const hasScroll = React.useMemo(
    () => main?.scrollHeight > main?.clientHeight,
    [main],
  );

  const defaultClassName = 'fixed top-0 bg-white dark:bg-gray-900 z-[45]';
  const className = classNames(
    defaultClassName,
    props.className,
    width,
    height,
    {
      'transition-all duration-200 ease-in-out': isLastDrawer,
      'translate-x-0 opacity-100 visible': opened,
      'translate-x-6 opacity-0 invisible': !opened,
      'right-[1.125rem]': hasScroll,
      'right-0': !hasScroll,
      'shadow-sm': isLastDrawer && withShadow,
    },
  );

  return (
    <aside {...props} className={className}>
      {children}
    </aside>
  );
};

const DrawerSubLayer: DrawerSubLayerType = ({
  width = 'w-[50rem] 2xl:w-[60rem] 4xl:w-[79rem]',
  height = 'h-screen',
  right = 'right-[27.875rem] 2xl:right-[33.5rem] 4xl:right-[44.125rem]',
  open = false,
  forceShadow,
  children,
  ...props
}) => {
  const { subOpened } = React.useContext(DrawerContext);

  const defaultClassName = `bg-gray-300/[.5] dark:bg-gray-700/[.5] backdrop-blur fixed top-0
    transition-all duration-200 ease-in-out z-[40]`;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const main = document.getElementById('main')!;
  const hasScroll = React.useMemo(
    () => main?.scrollHeight > main?.clientHeight,
    [main],
  );

  const className = classNames(
    defaultClassName,
    props.className,
    width,
    height,
    {
      'translate-x-0 opacity-100 visible': subOpened || open,
      'translate-x-6 opacity-0 invisible': !subOpened && !open,
      'right-[28rem] 2xl:right-[33.5rem] 4xl:right-[44.125rem]': hasScroll,
      [right]: !hasScroll,
      // Use this for situations when your sub panel is inside the main panel
      // and not in parallel
      [`before:absolute before:top-0 before:right-0 before:content-[""] 
        before:w-px before:h-full before:shadow-sm overflow-hidden`]:
        forceShadow,
    },
  );

  return (
    <div {...props} className={className}>
      {children}
    </div>
  );
};
Drawer.SubLayer = DrawerSubLayer;

const DrawerActivate: DrawerActivateType = ({
  drawer,
  subDrawer,
  disabled = false,
  children,
  ...props
}) => {
  const { openDrawer } = React.useContext(DrawerContext);
  const className = classNames('cursor-pointer', children.props.className, {
    'pointer-events-none opacity-80': disabled,
  });

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    openDrawer({ drawer, subDrawer });

    props.onClick?.(e);

    e.stopPropagation();
  }

  return React.cloneElement(children, {
    onClick: handleClick,
    ...props,
    className,
  });
};
Drawer.Activate = DrawerActivate;

const DrawerDeactivate: DrawerDeactivateType = ({
  onCloseConfirm,
  onClose,
  ...props
}) => {
  const { closeDrawer } = React.useContext(DrawerContext);
  const { openDialog } = React.useContext(DialogContext);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    if (onCloseConfirm && onCloseConfirm?.enabled) {
      const { dialogTitle, dialogDescription, options } = onCloseConfirm;

      openDialog(DrawerCloseConfirmDialog as DialogComponent, {
        dialogTitle,
        dialogDescription,
        options,
      });
    } else {
      closeDrawer();
    }
    if (onClose) {
      onClose();
    }
    props.onClick?.(e);
  }

  return (
    <button
      className="w-8 h-8 rounded bg-gray-950/[.06] dark:bg-gray-100/[.08] hover:bg-gray-800/[.04] dark:hover:bg-white/[.06] transition-colors duration-200 ease-in-out"
      onClick={handleClick}
      aria-label="Close drawer"
      {...props}
    >
      <Icon
        name="close"
        size={16}
        className="-mt-px fill-gray-950 dark:fill-white"
      />
    </button>
  );
};
Drawer.Deactivate = DrawerDeactivate;

const DrawerNavigateTo: DrawerNavigateToType = ({
  drawer,
  subDrawer,
  children,
  ...props
}) => {
  const { navigateTo } = React.useContext(DrawerContext);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    navigateTo({ drawer, subDrawer });
    props.onClick?.(e);
  }

  return React.cloneElement(children, {
    onClick: handleClick,
    ...props,
    className: children.props.className,
  });
};
Drawer.NavigateTo = DrawerNavigateTo;

const DrawerNavigateBack: DrawerNavigateBackType = ({
  children,
  onNavigateBackConfirm,
  ...props
}) => {
  const { navigateBack } = React.useContext(DrawerContext);
  const { openDialog } = React.useContext(DialogContext);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    if (onNavigateBackConfirm && onNavigateBackConfirm?.enabled) {
      const { dialogTitle, dialogDescription, options } = onNavigateBackConfirm;

      openDialog(DrawerCloseConfirmDialog as DialogComponent, {
        dialogTitle,
        dialogDescription,
        options,
      });
    } else {
      navigateBack();
    }

    props.onClick?.(e);
  }

  return children ? (
    React.cloneElement(children, {
      onClick: handleClick,
      ...props,
    })
  ) : (
    <button
      className="w-8 h-8 rounded bg-gray-950/[.06] dark:bg-gray-100/[.08] hover:bg-gray-800/[.04] dark:hover:bg-white/[.06] transition-colors duration-200 ease-in-out"
      onClick={handleClick}
      aria-label="Back to previous panel screen"
      {...props}
    >
      <Icon
        name="carrot-left"
        size={16}
        className="-mt-px fill-gray-950 dark:fill-white"
      />
    </button>
  );
};
Drawer.NavigateBack = DrawerNavigateBack;

const DrawerDeactivateSubLayer: DrawerDeactivateSubLayerType = ({
  children,
  ...props
}) => {
  const { closeSubDrawer } = React.useContext(DrawerContext);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    closeSubDrawer();
    props.onClick?.(e);
  }

  return children ? (
    React.cloneElement(children, {
      onClick: handleClick,
      ...props,
    })
  ) : (
    <button
      className="w-8 h-8 rounded bg-gray-950/[.06] dark:bg-gray-100/[.08] hover:bg-gray-800/[.04] dark:hover:bg-white/[.06] transition-colors duration-200 ease-in-out"
      onClick={handleClick}
      aria-label="Close drawer sub layer"
      {...props}
    >
      <Icon
        name="close"
        size={16}
        className="-mt-px fill-gray-950 dark:fill-white"
      />
    </button>
  );
};
Drawer.DeactivateSubLayer = DrawerDeactivateSubLayer;

export const withDrawer = <
  U extends JSX.IntrinsicAttributes & Omit<P, 'key' | 'isLastDrawer'>,
  P extends (DrawerProps & React.HTMLAttributes<HTMLElement>) | void = void,
>(
  Component: React.ComponentType<U>,
  props: DrawerProps & React.HTMLAttributes<HTMLElement> = {},
): React.ComponentType<DrawerProps & U> => {
  return ({ key, isLastDrawer, ...componentProps }: DrawerProps & U) => {
    return (
      <Drawer key={key} isLastDrawer={isLastDrawer} {...props}>
        <Component {...(componentProps as U)} />
      </Drawer>
    );
  };
};

export default Drawer;
